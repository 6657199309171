
let allPlayers = [
  {
    id: 1,
    first_name: "George",
    last_name: "Unitt",
    height: 69,
    weight: 202,
    position: "PG",
    points: 6,
    rebounds: 4,
    assists: 8,
    FT_percent: 70,
    FG_percent: 41,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626171.png",
  }, 
  {
    id: 2,
    first_name: "Karl",
    last_name: "Ovey",
    height: 82,
    weight: 203,
    position: "C",
    points: 17,
    rebounds: 3,
    assists: 1,
    FT_percent: 89,
    FG_percent: 54,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/203912.png",
  },
  {
    id: 3,
    first_name: "Kris",
    last_name: "Gonnard",
    height: 73,
    weight: 208,
    position: "PG",
    points: 11,
    rebounds: 7,
    assists: 3,
    FT_percent: 67,
    FG_percent: 28,
    img_URL: "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4431679.png&w=350&h=254",
  },
  {
    id: 4,
    first_name: "Sherman",
    last_name: "Morris",
    height: 69,
    weight: 174,
    position: "SG",
    points: 13,
    rebounds: 11,
    assists: 2,
    FT_percent: 81,
    FG_percent: 35,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626156.png",
  },
  {
    id: 5,
    first_name: "Adam",
    last_name: "Bryant",
    height: 79,
    weight: 216,
    position: "PF",
    points: 22,
    rebounds: 11,
    assists: 3,
    FT_percent: 91,
    FG_percent: 37,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626179.png",
  },
  {
    id: 6,
    first_name: "Odell",
    last_name: "Eade",
    height: 77,
    weight: 175,
    position: "SF",
    points: 20,
    rebounds: 6,
    assists: 4,
    FT_percent: 86,
    FG_percent: 34,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626174.png",
  },
  {
    id: 7,
    first_name: "Franklin",
    last_name: "Blacket",
    height: 83,
    weight: 196,
    position: "C",
    points: 11,
    rebounds: 10,
    assists: 3,
    FT_percent: 53,
    FG_percent: 46,
    img_URL: "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4278349.png",
  },
  {
    id: 8,
    first_name: "Erroll",
    last_name: "Janney",
    height: 79,
    weight: 188,
    position: "PF",
    points: 17,
    rebounds: 1,
    assists: 2,
    FT_percent: 78,
    FG_percent: 37,
    img_URL: "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4278594.png",
  },
  {
   id: 9,
    first_name: "Keefer",
    last_name: "Haine",
    height: 69,
    weight: 206,
    position: "SG",
    points: 10,
    rebounds: 5,
    assists: 4,
    FT_percent: 91,
    FG_percent: 77,
    img_URL: "https://a.espncdn.com/combiner/i?img=/i/headshots/mens-college-basketball/players/full/4431689.png&w=350&h=254",
  },
  {
    id: 10,
    first_name: "Gavin",
    last_name: "Smiths",
    height: 69,
    weight: 213,
    position: "PG",
    points: 24,
    rebounds: 11,
    assists: 2,
    FT_percent: 49,
    FG_percent: 32,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626178.png",
  },
  {
    id: 11,
    first_name: "Dennis",
    last_name: "Gass",
    height: 81,
    weight: 185,
    position: "C",
    points: 21,
    rebounds: 11,
    assists: 4,
    FT_percent: 96,
    FG_percent: 69,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/203998.png",
  },
  {
    id: 12,
    first_name: "Alfredo",
    last_name: "Beacroft",
    height: 72,
    weight: 207,
    position: "SG",
    points: 9,
    rebounds: 4,
    assists: 4,
    FT_percent: 53,
    FG_percent: 39,
    img_URL: "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1628961.png",
  },
  {
    id: 13,
    first_name: "Darius",
    last_name: "Comettoi",
    height: 76,
    weight: 222,
    position: "PG",
    points: 24,
    rebounds: 5,
    assists: 2,
    FT_percent: 85,
    FG_percent: 68,
    img_URL: "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1629742.png",
  },
  {
    id: 14,
    first_name: "Tatum",
    last_name: "Hartland",
    height: 81,
    weight: 190,
    position: "C",
    points: 15,
    rebounds: 3,
    assists: 3,
    FT_percent: 83,
    FG_percent: 65,
    img_URL: "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1629679.png",
  },
  {
    id: 15,
    first_name: "Shawn",
    last_name: "Prender",
    height: 73,
    weight: 200,
    position: "SG",
    points: 19,
    rebounds: 11,
    assists: 6,
    FT_percent: 81,
    FG_percent: 54,
    img_URL: "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626158.png",
  }, 
  {
    id: 16,
    first_name: "Hakeem",
    last_name: "Lowson",
    height: 82,
    weight: 225,
    position: "C",
    points: 13,
    rebounds: 5,
    assists: 3,
    FT_percent: 61,
    FG_percent: 79,
    img_URL: "https://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626161.png",
  },
  {
    id: 17,
    first_name: "Milo",
    last_name: "Pinnell",
    height: 75,
    weight: 227,
    position: "SG",
    points: 22,
    rebounds: 6,
    assists: 5,
    FT_percent: 69,
    FG_percent: 47,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626159.png",
  },
  {
    id: 18,
    first_name: "Will",
    last_name: "Domeny",
    height: 76,
    weight: 194,
    position: "PG",
    points: 8,
    rebounds: 3,
    assists: 2,
    FT_percent: 57,
    FG_percent: 48,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626209.png",
  }, 
  {
    id: 19,
    first_name: "Kyle",
    last_name: "Lepick",
    height: 77,
    weight: 166,
    position: "SF",
    points: 21,
    rebounds: 3,
    assists: 7,
    FT_percent: 60,
    FG_percent: 30,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626157.png",
  },
  {
    id: 20,
    first_name: "Rudy",
    last_name: "Matterson",
    height: 78,
    weight: 211,
    position: "PF",
    points: 8,
    rebounds: 4,
    assists: 3,
    FT_percent: 62,
    FG_percent: 79,
    img_URL: "http://ak-static.cms.nba.com/wp-content/uploads/headshots/nba/latest/260x190/1626144.png",
  }
];

export default allPlayers;